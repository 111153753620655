<template>
	<Navbar/>	
</template>

<script>
	import Navbar from "./Navbar";
    export default {
        name: "app-header",
		components: {
            Navbar
		}
    }
</script>


