<template>
	<div>
		<header class="header">
			<div class="container-fluid">
				<b-navbar toggleable="xl" type="gr">
					<b-navbar-brand href="#">
						<img src="../assets/styles/images/logo.svg" class="d-inline-block align-top"
							 alt="SiteAuditor">
					</b-navbar-brand>
					<div class="navbar-cnt">
						<b-navbar-toggle target="nav-collapse" class="navbar-toggler">
							<template #default>
								<span class="svg-image-menu"></span>
							</template>
						</b-navbar-toggle>

						<b-collapse id="nav-collapse" is-nav>
							<b-navbar-nav class="me-auto">
								<b-nav-item :to="{name: 'dashboard.leads'}"
											:active="$route.name.includes('dashboard.leads')"
											:disabled="isDisableNavItem('dashboard.leads')">
									<i class="icon-menu-icon1"></i>View My Leads
								</b-nav-item>

								<b-nav-item :to="{name: 'dashboard.generate-report'}" exact exact-active-class="active"
											:disabled="isDisableNavItem('dashboard.generate-report')">
									<i class="icon-menu-icon2"></i> Generate Reports
								</b-nav-item>

								<b-nav-item :to="{name: 'dashboard.setup-form'}"
											:active="$route.name.includes('dashboard.setup-form')"
											:disabled="isDisableNavItem('dashboard.setup-form')">
									<i class="icon-menu-icon3"></i> Setup Audit Form
								</b-nav-item>

								<b-nav-item :to="{name: 'dashboard.format-report'}" exact exact-active-class="active"
											:disabled="isDisableNavItem('dashboard.format-report')">
									<i class="icon-menu-icon5"></i>Format Report
								</b-nav-item>

								<b-nav-item :to="{name: 'dashboard.integrations'}" exact exact-active-class="active"
											:disabled="isDisableNavItem('dashboard.integrations')">
									<i class="icon-menu-icon4"></i>Integrations
								</b-nav-item>

							</b-navbar-nav>

							<b-nav-form class="d-flex navbar-form">
								<router-link tag="button" class=" btn btn-primary btn-plan" :to="{name: 'dashboard.pick-plan'}">
									Pick plan
								</router-link>
								<div class="navbar-user">
									<b-dropdown toggle-class="text-decoration-none" right no-caret variant="link">
										<template #button-content>
											<a class="navbar-user__img" href="#"><img
													src="../assets/styles/images/user.svg" alt=""></a>
										</template>
											<h6>Account</h6>
											<b-dropdown-item :to="{name: 'dashboard.settings.account'}"><i class="svg-image-settings-acc"></i> Settings</b-dropdown-item>
											<b-dropdown-item :to="{name: 'dashboard.settings.billing'}"><i class="svg-image-billing-acc"></i> Billing & My Plan</b-dropdown-item>
											<b-dropdown-item :to="{name: 'dashboard.pick-plan'}"><i class="svg-image-pick-plan-acc"></i> Pick Plan</b-dropdown-item>
											<b-dropdown-divider></b-dropdown-divider>
											<b-dropdown-item @click="logout"><i class="svg-image-logout-acc"></i> Logout</b-dropdown-item>
									</b-dropdown>
								</div>
							</b-nav-form>
						</b-collapse>
					</div>
				</b-navbar>

			</div>
		</header>
	</div>
</template>

<script>
    export default {
        name: "app-header",
        methods: {
            logout() {
                this.$router.push('/logout').catch(() => {})
            },
			isDisableNavItem(route) {
                let props = this.$router.resolve({
                    name: route
                });
                return !props.route.meta.showIfSuspended && this.$store.getters['auth/isUserSuspended']
            }
        }

    }
</script>
