<template>
	<div v-if="pageLoaded" class="gr-app">
		<Header></Header>
		<main class="main">
			<router-view></router-view>
		</main>
	</div>
</template>

<script>
    import Header from "../components/Header";
    import {mapGetters} from "vuex";
    export default {
        name: "Dashboard",
        components: {Header},
        data() {
            return {
                pageLoaded: false
            }
        },
        created() {
            if (this.$store.getters['auth/isAuthenticated']) {
                this.$store.dispatch('auth/me')
                    .then(() => {
                        $crisp.push(["set", "user:email", this.user.email]); // eslint-disable-line
                        if (!this.$route.meta.showIfSuspended && this.$store.getters['auth/isUserSuspended']) {
                           this.$router.push({name: 'dashboard.pick-plan'}).catch(() => {});
                        }
                        this.pageLoaded = true;
                    })
                    .catch((error) => {
                        this.showErrorNotifications(error);
                    })
            }

        },
        computed: {
            ...mapGetters({
                user: "auth/getUser"
            })
        }
    }
</script>

<style lang="scss">

</style>
